<template>
  <div>
    <div class="flix-text-center" :class="{'flix-form-group': open}">
      <a href="#" @click.prevent="open = !open" class="flix-html-a flix-html-h3">{{ $t('message.embed') }} <flixIcon v-if="!open" :id="'chevron-up'" /><flixIcon v-if="open" :id="'chevron-down'" /></a>
    </div>
    <transition name="flixFadeIn">
      <embedCode :id="id" :key="open" v-if="open"/>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    embedCode () { return import('@/components/embed/menu') }
  },
  props: {
    id: [Number, String]
  },
  data () {
    return {
      open: false
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
